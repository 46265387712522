import {useContext, useEffect, useState} from "react";
import {MainContextProvider} from "../../../../context/MainContext";
import customAxios from "../../../../service/api/interceptors";

export const useSettings = () => {
    const { userData, setLoading, creditCount, setCurrentTimer } = useContext(MainContextProvider);
    const role = userData?.status?.type;
    const [showPaymentMethod, setShowPaymentMethod] = useState("");

    const getSubscription = async () => {
        try {
            const { data: companies } = await customAxios.get(`/company/company/get-companies`);
            const { data: subscriptionData } = await customAxios.get(`/company/${companies[0].id}/subscription`);
            if (subscriptionData.stripe_subscription_id) {
                setShowPaymentMethod(subscriptionData.stripe_subscription_id + "");
            }
        } catch (e) {
            console.error("Failed to fetch subscription data", e);
        }
    };

    useEffect(() => {
        if (creditCount.isCreditSubscription) {
            getSubscription();
        }
        setCurrentTimer('redirect')
    }, [creditCount]);

    return {
        role,
        userData,
        creditCount,
        showPaymentMethod,
    };
};