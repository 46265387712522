import React, {useContext, useEffect, useState} from "react";
import "./header_timer.scss"
import {MainContextProvider} from "../../../context/MainContext";
import customAxios from "../../../service/api/interceptors";
import {HeaderTimerType} from "../../../helpers/types";
import ModalContainer from "../../ModalContainer/ModalContainer";
import Select from "../../UI/Select/Select";
import TextArea from "../../UI/TextArea/TextArea";
import {ModalLoader} from "../ModalLoader/ModalLoader";
import {useHeaderTimer} from "./useHeaderTimer";
import {useTimer} from "../../../context/TimerContext";

const formatTime = (time: number): string => {
    const hours = String(Math.floor(time / 3600)).padStart(2, '0');
    const minutes = String(Math.floor((time % 3600) / 60)).padStart(2, '0');
    const seconds = String(time % 60).padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
};

export const HeaderTimer: React.FC = () => {
    const { time, isRunning, startTimer, stopTimer, startedTimerData, timerData } = useTimer();

    /*if (loading) {
        return <div className={'header_timer'}><div className={'header_timer__type'}>Loading...</div></div>
    }*/

    if (!startedTimerData && !isRunning ) {
        return <div />
    }

    return (
        <div className={'header_timer'}>
            <div className={'header_timer__info'}>
                <div className={'header_timer__company'}>
                    {(timerData || startedTimerData)?.company_name}&nbsp;-&nbsp;
                </div>
                <div className={'header_timer__type'}>
                    {(timerData || startedTimerData)?.type}
                </div>
            </div>
            {isRunning && <div className={'header_timer__block'}>
                {formatTime(time)}
            </div>}
            <button onClick={isRunning ? stopTimer : startTimer} style={{ borderColor: (isRunning) ? '#FF5C5C' : '#888888' }} className={'header_timer__button'}>
                {isRunning && <div className={'header_timer__square'}/>}
                {!isRunning && <div className={'header_timer__triangle'}/>}
            </button>
            {/*<button onClick={(headerTimer?.created_at) ? stopTimer : setTimerData} style={{ borderColor: (headerTimer?.created_at) ? '#FF5C5C' : '#888888' }} className={'header_timer__button'}>
                {(headerTimer?.created_at) && <div className={'header_timer__square'}/>}
                {(!headerTimer?.created_at) && <div className={'header_timer__triangle'}/>}
            </button>*/}
            {/*<StopTimer
                isOpen={!!((currentTimer === 'stop' || currentTimer === 'redirect') && headerTimer?.created_at)}
                headerTimer={headerTimer} close={() => setCurrentTimer('')} setTimeElapsed={setTimeElapsed}
            />*/}
        </div>
    )
}

/*
const StopTimer = ({ isOpen, headerTimer, close, setTimeElapsed }: { isOpen: boolean, headerTimer: HeaderTimerType | null, close: any, setTimeElapsed: any }) => {
    const [tag, setTag] = useState('')
    const [tags, setTags] = useState<{ label: string, value: string }[]>([])
    const { setHeaderTimer, setCurrentTimer, currentTimer } = useContext(MainContextProvider)
    const [loading, setLoading] = useState(false)
    const [note, setNote] = useState('')
    const [notePlaceholder, setNotePlaceholder] = useState('')

    const stopTimer = async () => {
        setLoading(true)
        try {
            const { status, data } = await customAxios.put(`/timely/event/${headerTimer?.timer_id}/stop-timer`, {
                tag_id: tag, note: note || notePlaceholder
            })
            if (status === 200) {
                if (window.location.pathname.includes('offer') || window.location.pathname.includes('legal-request')) {
                    // @ts-ignore
                    setHeaderTimer({ ...headerTimer, created_at: null, offer_id: window.location.pathname.split('/')[2], type: `Communication re Request #${window.location.pathname.split('/')[2]}` })
                } else {
                    setHeaderTimer(null)
                }
                setTimeElapsed(0)
                // @ts-ignore
                clearInterval(timerInterval)
                timerInterval = null
                setCurrentTimer('')

                //window.location.reload()
            }
        } catch (e) {}
        setLoading(false)
    }

    const getTags = async () => {
        try {
            const { status, data } = await customAxios.get(`/timely/tags`)
            setTags(data.map((item: { title: string, id: string }) => ({ value: item.id, label: item.title })))
            setTag(data[0].id)
        } catch (e) {

        }
    }

    useEffect(() => {
        if (isOpen) {
            getTags().then()
            setNotePlaceholder(headerTimer?.type + '')
            setNote(headerTimer?.type + '')
        }
    }, [isOpen])

    const modalClose = async () => {
        if (currentTimer === "redirect") {
            stopTimer().then()
            close()
        } else {
            close()
        }
    }

    return (
        <div className="create_manager_offer" style={{ position: 'relative' }}>
            {loading && <ModalLoader />}
            <ModalContainer isOpen={isOpen} heading={'Stop timer'} close={modalClose}>
                <div style={{ padding: '10px 0' }}>
                    <Select
                        label={'Tag'}
                        value={tag}
                        options={tags}
                        onChange={(e) => setTag(e.value)}
                    />
                    <div className={'mt10'}>
                        <TextArea
                            label={'Comment'}
                            otherProps={{
                                rows: 8,
                                value: note,
                                onChange: e => setNote(e.target.value),
                                placeholder: notePlaceholder
                            }}
                        />
                    </div>

                    <button onClick={stopTimer} className={'w100 btn-black text-uppercase mt15'}>stop timer</button>
                </div>
            </ModalContainer>
        </div>
    )
}*/
