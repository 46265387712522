import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface SimpleEditorProps {
    value: string;
    onChange: (value: string) => void;
}

export const NewEditor = ({ value, onChange }: SimpleEditorProps) => {
    const modules = {
        toolbar: [
            // [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }], // Списки
            //['link'],
            ['clean']  // Кнопка для очистки форматирования
        ],
    };

    return (
        <ReactQuill
            className="quill-editor"
            style={{ maxWidth: '100%', wordBreak: 'break-word' }}
            theme="snow"
            value={value}
            onChange={onChange}
            modules={modules} // Подключаем модули с настройками
        />
    );
}