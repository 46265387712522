import ModalContainer from "../../../../components/ModalContainer";
import React, {useEffect, useState} from "react";
import {Input, ReactEditor, ReactSelect} from "../../../../components/UI";
import "./create_business_goal.scss"
import {controlErrorFunc} from "../../../../components/UI/ControlValid/controlErrorValid";
import {toastifyError} from "../../../../service/toastify/toastify";
import customAxios from "../../../../service/api/interceptors";
import {useLocation} from "react-router-dom";
import {CustomLoader} from "../../../../components/UI/CustomLoader/CustomLoader";
import {ModalLoader} from "../../../../components/other/ModalLoader/ModalLoader";
import {BusinessGoalType} from "../../BusinessGoal.type";

type Props = {
    isOpen: boolean
    updateItems: () => void
    close: () => void
    company_id: string
}

type UpdateProps = {
    isOpen: boolean
    updateItems: () => void
    close: () => void
    obj: BusinessGoalType
}

const initialValues = {
    title: '',
    priority: '1',
    description: ''
}

const validationObj = {
    title: { required: true, maxLength: 255 },
    priority: { required: true },
    description: { required: true, maxLength: 5000 },
}

export const UpdateBusinessGoal: React.FC<UpdateProps> = ({ isOpen, updateItems, close, obj }) => {
    const [loading, setLoading] = useState(false)
    const [values, setValues] = useState<{ [key: string]: string }>({})
    const [errors, setErrors] = useState<{[key: string]: string}>({})
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);


    useEffect(() => {
        if (isOpen) {
            const { title, priority, description } = obj

            setValues({
                title, priority: priority + '', description
            })
        }
    },[isOpen])

    const createBusinessGoal = async () => {
        const validReq = controlErrorFunc({ obj: values, config: validationObj})
        if(Object.keys(validReq).length >= 1) {
            setErrors(validReq)
            toastifyError('Please correctly fill all fields in the form')
            return false;
        }
        setErrors({})

        setLoading(true)
        try {
            const { status, data } = await customAxios.put(`/business-goal/${obj.id}`, {
                description: values.description,
                priority: values.priority,
                title : values.title
            })

            if (status === 200 || status === 201) {
                close()
                updateItems()
            }
        } catch (e) {}
        setLoading(false)
    }

    return (
        <BusinessGoalModalJsx
            loading={loading}
            isOpen={isOpen}
            values={values}
            setValues={setValues}
            errors={errors}
            createBusinessGoal={createBusinessGoal}
            close={close}
            button={'Update'}
        />
    )
}

export const CreateBusinessGoal: React.FC<Props> = ({ isOpen, updateItems, close, company_id }) => {
    const [loading, setLoading] = useState(false)
    const [values, setValues] = useState<{ [key: string]: string }>({})
    const [errors, setErrors] = useState<{[key: string]: string}>({})

    useEffect(() => {
        if (isOpen) {
            setValues(JSON.parse(JSON.stringify(initialValues)))
        }
    },[isOpen])
    
    const createBusinessGoal = async () => {
        const validReq = controlErrorFunc({ obj: values, config: validationObj})
        if(Object.keys(validReq).length >= 1) {
            setErrors(validReq)
            toastifyError('Please correctly fill all fields in the form')
            return false;
        }
        setErrors({})

        setLoading(true)
        try {
            const { status, data } = await customAxios.post(`/business-goal`, {
                description: values.description,
                priority: values.priority,
                company_id: company_id,
                title : values.title,
                stage: 2
            })

            if (status === 200 || status === 201) {
                close()
                updateItems()
            }
        } catch (e) {}
        setLoading(false)
    }

    return (
        <BusinessGoalModalJsx
            loading={loading}
            isOpen={isOpen}
            values={values}
            setValues={setValues}
            errors={errors}
            createBusinessGoal={createBusinessGoal}
            close={close}
        />
    )
}

const BusinessGoalModalJsx: React.FC<any> = ({ loading, isOpen, values, setValues, errors, createBusinessGoal, close, button }) => {
    return (
        <div className={'create_business_goal relative'}>
            <ModalContainer isOpen={isOpen} heading={'Legal Project Details'} close={close}>
                <div className={'relative'}>
                    {loading && <ModalLoader />}
                    <h3>Main Info</h3>
                    <div className={'mt15'}>
                        <Input
                            label={'Title'}
                            otherProps={{
                                value: values.title,
                                onChange: e => setValues({ ...values, title: e.target.value })
                            }}
                            valid={!errors?.title}
                            errorMessage={errors.title}
                        />
                    </div>
                    <div className={'mt15'}>
                        <ReactSelect
                            label={'Priority'}
                            value={values.priority || '1'}
                            options={[
                                { value: '1', label: 'Low' },
                                { value: '2', label: 'Medium' },
                                { value: '3', label: 'High' },
                            ]}
                            onChange={e => setValues({ ...values, priority: e.value })}
                            valid={!errors?.priority}
                            errorMessage={errors.priority}
                        />
                    </div>
                    <div className={'mt15'}>
                        <ReactEditor
                            value={values.description}
                            onChange={e => setValues({ ...values, description: e })}
                            valid={!errors?.description}
                            errorMessage={errors.description}
                        />
                    </div>
                    <button onClick={createBusinessGoal} className={'w100 btn-black text-uppercase mt20'}>{button || 'CREATE'}</button>
                </div>
            </ModalContainer>
        </div>
    )
}