import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { MainContext } from './context/MainContext'
import AppStack from './containers/Layout/AppStack'
import {TimerProvider} from "./context/TimerContext";

const App:React.FC = () => (
  <>
    <MainContext>
        <TimerProvider>
            <Router>
                <AppStack />
            </Router>
        </TimerProvider>
    </MainContext>
  </>
)

export default App
