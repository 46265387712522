import React, {useContext, useEffect, useRef, useState} from "react";
import customAxios from "../service/api/interceptors";
import {CompanyType} from "../types/company.type";
import {MainContextProvider} from "./MainContext";
import ModalContainer from "../components/ModalContainer";
import {ModalLoader} from "../components/other/ModalLoader/ModalLoader";
import {TextArea} from "../components/UI";
import Select from "../components/UI/Select/Select";

interface TimerContextProps {
    time: number;
    isRunning: boolean;
    startTimer: () => void;
    stopTimer: () => void;
    resetTimer: () => void;
    timerData: TimerDataType | null
    setTimerData: (key: TimerDataType) => void;
    startedTimerData: TimerDataType | null
    setStartedTimerData: (key: TimerDataType | null) => void;
}

type TimerDataType = {
    company_id: string,
    offer_id: string,
    company_name: string,
    type: string,
    created_at?: null | number,
    timer_id?: null | number
}

export const TimerContext = React.createContext<TimerContextProps | undefined>(undefined);

export const TimerProvider: React.FC = ({ children }) => {
    const { userData } = useContext(MainContextProvider)
    const [time, setTime] = useState<number>(0);
    const [isRunning, setIsRunning] = useState<boolean>(false);
    const timerRef = useRef<NodeJS.Timeout | null>(null);
    const [timerData, setTimerData] = useState<TimerDataType | null>(null)
    const [startedTimerData, setStartedTimerData] = useState<TimerDataType | null>(null)
    const [isCloseTimer, setIsCloseTimer] = useState(false)

    const startTimer = async () => {
        if (!isRunning) {
            try {
                const { status, data } = await customAxios.post(`/timely/event/add`, {
                    company_id: startedTimerData?.company_id,
                    offer_id: startedTimerData?.offer_id
                })
                if (status === 200) {
                    setTimerData({
                        ...JSON.parse(JSON.stringify(startedTimerData)),
                        timer_id: data.id
                    })
                }
            } catch (e) {

            }
            setIsRunning(true);
            timerRef.current = setInterval(() => {
                setTime((prevTime: number) => prevTime + 1);
            }, 1000);
        }
    };

    const closeTimer = () => {
        setIsCloseTimer(false)
        setTimerData(null)
        if (isRunning) {
            setIsRunning(false);
            if (timerRef.current) {
                setTime(0)
                clearInterval(timerRef.current);
                timerRef.current = null;
            }
        }
    }

    const stopTimer = () => {
        if (isRunning) {
            setIsCloseTimer(true)
        }
    };

    const resetTimer = () => {
        setTime(0);
        stopTimer();
    };

    const getItem = async () => {
        try {
            const { status, data } = await customAxios.get(`/timely/current-event`)
            if (status === 200 && data) {
                const { data: companyItems } = await customAxios.get(`/company/company/get-companies?expand=client,company`)
                if (!data?.offer_id) {
                    return
                }
                const { data: offerData } = await customAxios.get(`/company/client/kanban/${data?.offer_id}?expand=project,company`)
                setTimerData({
                    created_at: data.start_at,
                    company_name: companyItems.find((el: CompanyType) => +el.id === +data.company_id)?.name,
                    company_id: data.company_id,
                    type: `Communication re Project #${offerData?.offer?.id}`,
                    timer_id: data.id,
                    offer_id: data.offer_id
                })
                const now_timestamp = (+(new Date()) / 1000).toFixed()
                const seconds = +now_timestamp - (Number(data.start_at))
                setTime(seconds)
                setIsRunning(true)
                timerRef.current = setInterval(() => {
                    setTime((prevTime: number) => prevTime + 1);
                }, 1000);
            }
        } catch (e) {}
    }

    useEffect(() => {
        if (userData?.status.type_code === 300 && localStorage.getItem("accessToken")) getItem().then()

        return () => {
            if (timerRef.current) {
                clearInterval(timerRef.current);
            }
        };
    }, [userData]);

    return (
        <>
            <TimerContext.Provider value={{ time, isRunning, startTimer, stopTimer, resetTimer, startedTimerData, setStartedTimerData, timerData, setTimerData }}>
                {children}
            </TimerContext.Provider>
            <StopTimer isOpen={isCloseTimer} close={closeTimer} timerData={timerData} closeModal={() => setIsCloseTimer(false)} />
        </>

    );
};

export const useTimer = (): TimerContextProps => {
    const context = React.useContext(TimerContext);
    if (!context) {
        throw new Error('useTimer must be used within a TimerProvider');
    }
    return context;
};

const StopTimer = ({ isOpen, close, timerData, closeModal }: { isOpen: boolean, close: any, timerData: TimerDataType | null, closeModal: () => void }) => {
    const [tag, setTag] = useState('')
    const [tags, setTags] = useState<{ label: string, value: string }[]>([])
    const [loading, setLoading] = useState(false)
    const [note, setNote] = useState('')
    const [notePlaceholder, setNotePlaceholder] = useState('')

    const stopTimer = async () => {
        setLoading(true)
        try {
            const { status, data } = await customAxios.put(`/timely/event/${timerData?.timer_id}/stop-timer`, {
                tag_id: tag, note: note || notePlaceholder
            })
            if (status === 200) {
                close()
            }
        } catch (e) {}
        setLoading(false)
    }

    const getTags = async () => {
        try {
            const { status, data } = await customAxios.get(`/timely/tags`)
            setTags(data.map((item: { title: string, id: string }) => ({ value: item.id, label: item.title })))
            setTag(data[0].id)
        } catch (e) {}
    }

    useEffect(() => {
        if (isOpen) {
            getTags().then()
            setNotePlaceholder(timerData?.type || '')
            setNote(timerData?.type || '')
        }
    }, [isOpen])

    const modalClose = async () => {
        /*if (currentTimer === "redirect") {
            stopTimer().then()
            close()
        } else {
            close()
        }*/
        if (window.location.pathname.includes("/offer/") || window.location.pathname.includes("/partner-request/")) {
            closeModal()
        } else {
            stopTimer()
        }
    }

    return (
        <div className="create_manager_offer" style={{ position: 'relative' }}>
            {loading && <ModalLoader />}
            <ModalContainer isOpen={isOpen} heading={'Stop timer'} close={modalClose}>
                <div style={{ padding: '10px 0' }}>
                    <Select
                        label={'Tag'}
                        value={tag}
                        options={tags}
                        onChange={(e: any) => setTag(e.value)}
                    />
                    <div className={'mt10'}>
                        <TextArea
                            label={'Comment'}
                            otherProps={{
                                rows: 8,
                                value: note,
                                onChange: e => setNote(e.target.value),
                                placeholder: notePlaceholder
                            }}
                        />
                    </div>

                    <button onClick={stopTimer} className={'w100 btn-black text-uppercase mt15'}>stop timer</button>
                </div>
            </ModalContainer>
        </div>
    )
}