import React, {useContext, useEffect, useState} from "react";
import ModalContainer from "../../../../../components/ModalContainer";
import Select from "../../../../../components/UI/Select/Select";
import {Input, TextArea} from "../../../../../components/UI";
import customAxios from "../../../../../service/api/interceptors";
import {MainContextProvider} from "../../../../../context/MainContext";
import {PlannerSuggestionItemType} from "./PlannerPage";
import logoIcon from "../../../../../assets/images/logo.svg";
import {CreditControlModal} from "../../../../Modals/Credits/CreditControlModal";
import {useHistory} from "react-router-dom";

type Props = {
    isOpen: boolean
    close: () => void
    ok: () => void
    updateItems: () => void
    company_id: string
    item?: PlannerSuggestionItemType
    isSubmit?: boolean
}

export const NewPlannerRequestModal: React.FC<Props> = ({
    isOpen, close, ok, updateItems, company_id, item, isSubmit
}) => {
    const { setLoading, creditCount, getCreditCount, setModalData, setModalType } = useContext(MainContextProvider)
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [category, setCategory] = useState('')
    const [errors, setErrors] = useState({ title: '' })
    const [haveToApprove, setHaveToApprove] = useState(false)
    const [noCreditsModal, setNoCreditsModal] = useState(false)
    const [requestCreatedModal, setRequestCreatedModal] = useState(false)
    const [companyCreditsCount, setCompanyCreditsCount] = useState(0)
    const [categories, setCategories] = useState([])
    const [haveToBeApproveModal, setHaveToBeApproveModal] = useState(false)
    const history = useHistory()

    const getCreditsCount = async () => {
        try {
            const { data: categoriesData } = await customAxios.get(`/company/project/category`)
            if (!item) setCategory(categoriesData[0]?.id)
            const { status, data } = await customAxios.get(`/company/${company_id}/credits`)
            if (status === 200) {
                setCategories(categoriesData.map((el: { id: string, title: string }) => ({ value: el.id, label: el.title })))
                setCompanyCreditsCount(data?.total_count || 0)
                if (isSubmit && creditCount.isCreditSubscription) {
                    setHaveToBeApproveModal(true)
                }
            }
        } catch (e) {}
    }

    const validateValues = () => {
        if (!title) {
            setErrors({ title: 'This field is required' })
            return false
        }

        setErrors({ title: '' })
        return true
    }

    const updateItem = async (createRequestParam = false) => {
        setLoading(true)
        try {
            const { status, data } = await customAxios.put(`/project-suggestion/${item?.id}`, {
                title, description, category_id: category
            })
            if (status === 200) {
                if (createRequestParam) {
                    await createRequestFromSuggestion(item?.id || 1)
                } else {
                    close()
                    updateItems()
                }
            }
        } catch (e) {}
        setLoading(false)
    }

    const createRequestFromSuggestion = async (id: number) => {
        try {
            const { status, data } = await customAxios.post(`/project-suggestion/${id}/request`)
            if (status === 200 || status === 201) {
                if (creditCount.isCreditSubscription) {
                    setModalType('credit_delete_modal')
                    setModalData({
                        creditsSum: 1,
                        title: title,
                        subtitle: 'your request has been sent to your VLO AND WILL NOW APPEAR ON YOUR ACTIVE BOARD.'
                    })
                    setTimeout(getCreditCount, 2000)
                }
                updateItems()
                close()
            }
        } catch (e) {}
    }

    const createData = async (createRequestParam = false) => {
        if (!validateValues()) {
            return;
        }

        if (item) {
            await updateItem(createRequestParam)
            return
        }

        setLoading(true)
        try {
            const { status, data } = await customAxios.post(`/project-suggestion/planned`, {
                title,
                description,
                importance: 1,
                price_estimate: 0,
                time_estimate: 0,
                company_id,
                category_id: category
            })
            if (status === 200 || status === 201) {
                if (createRequestParam) {
                    await createRequestFromSuggestion(data.id)
                } else {
                    close()
                    updateItems()
                }
            }
        } catch (e) {}
        setLoading(false)
    }

    useEffect(() => {
        if (company_id && isOpen) {
            getCreditsCount().then()
        }

        if (isOpen && item) {
            setTitle(item.title)
            setDescription(item.description)
            setCategory(item.category_id + '')
        } else {
            setTitle('')
            setDescription('')
            setHaveToApprove(false)
            setNoCreditsModal(false)
            setRequestCreatedModal(false)
            setCompanyCreditsCount(0)
        }
    }, [isOpen, company_id])

    const createRequest = async () => {
        if (!validateValues()) {
            return;
        }

        if (companyCreditsCount > 0) {
            setHaveToApprove(true)
        } else {
            setNoCreditsModal(true)
        }
    }

    return (
        <ModalContainer isOpen={isOpen} close={close}>
            <>
                {haveToBeApproveModal && <CreditControlModal
                    submit={() => {
                        createData(true)
                    }}
                    close={() => setHaveToBeApproveModal(false)}
                    creditsSum={1}
                    commonTitle={title}
                    noCreditsSubtitle={'INSUFFICIENT CREDITs'}
                    noCreditsDescription={`This request requires 1 Credit. You do not have enough Credits to proceed. Please buy more Credits and then locate this request in your Planner, where you can submit it to your VLO.`}
                />}
                {!haveToBeApproveModal && <div>
                    <div className={'heading4'}>
                        New request
                    </div>
                    <p className={'p1 mt15'}>
                        Please provide details about your request. You can submit your request to your VLO to begin
                        work immediately or save it to your Planner. <b>Submitting a request costs 1 Credit</b> and will
                        move the request to your Active Board.
                    </p>
                    <h6 className={'heading6 mt20'}>CATEGORY</h6>
                    <Select
                        value={category || ''}
                        options={categories}
                        onChange={(el) => setCategory(el.value)}
                    />
                    <h6 className={'heading6 mt10'}>TITLE</h6>
                    <Input
                        className={'mt'}
                        otherProps={{
                            value: title,
                            onChange: e => setTitle(e.target.value)
                        }}
                        valid={!errors.title}
                    />
                    <h6 className={'heading6 mt15'}>DESCRIPTION</h6>
                    <TextArea
                        otherProps={{
                            placeholder: 'Describe your request here...',
                            rows: 6,
                            value: description,
                            onChange: e => setDescription(e.target.value)
                        }}
                    />
                    <button onClick={() => {
                        if (validateValues()) setHaveToBeApproveModal(true)
                    }} className={'btn btn-black w100 text-uppercase mt20'}>
                        {`SUBMIT TO VLO${creditCount.isCreditSubscription ? ' (1 CREDIT)' : ''}`}
                    </button>
                    <button onClick={() => createData(false)} className={'btn-white w100 text-uppercase mt5'}>
                        SAVE To PLANNER (FREE)
                    </button>
                </div>}
            </>
        </ModalContainer>
    )
}

type ApproveSubmitToVloProps = {
    title: string
    credits: number
    close: () => void
    ok: () => void
}

const ApproveSubmitToVlo: React.FC<ApproveSubmitToVloProps> = ({ title, credits, close, ok }) => {
    return (
        <div>
            <div className={'flex justifyCenter'}>
                <img src={logoIcon} alt=""/>
            </div>
            <div className="heading4 text-center mt40">
                {title}
            </div>
            <p className={'p1 color-blue text-uppercase text-center mt20'}>
                1 credit will be charged. This will enable your VLO
                to begin working on your request.
            </p>
            <div className={'mt20'}>
                <div className={'planner-page__modal-container'}>
                    Credits Balance: <b className={'text-weight-600'}>{credits}</b>
                </div>
            </div>
            <div>
                <button onClick={ok} className={'btn btn-black w100 text-uppercase mt40'}>
                    SUBMIT TO VLO
                </button>
                <button onClick={close} className={'btn-white w100 text-uppercase mt10'}>
                    GO BACK
                </button>
            </div>
        </div>
    )
}




type NoCreditsModalProps = {
    title: string
    credits: number
    close: () => void
    ok: () => void
}

const NoCreditsModal: React.FC<NoCreditsModalProps> = ({ title, credits, close, ok }) => {
    return (
        <div>
            <div className={'flex justifyCenter'}>
                <img src={logoIcon} alt=""/>
            </div>
            <div className="heading4 text-center mt40">
                {title}
            </div>
            <p className={'p1 color-blue text-uppercase text-center mt20'}>
                INSUFFICIENT CREDITs
            </p>
            <p className={'p1 text-center mt20'}>
                This request requires <b className={'text-weight-600'}>1 Credit</b>. You do not have enough Credits to proceed.
                Please buy more Credits and then locate this request in your Planner,
                where you can submit it to your VLO.
            </p>
            <div className={'mt20'}>
                <div className={'planner-page__modal-container'}>
                    Credits Balance: <b className={'text-weight-600'} style={{ color: '#FF0000' }}>{'0'}</b>
                </div>
            </div>
            <div>
                <button onClick={() => window.location.href = '/settings/payment-plan'} className={'btn btn-black w100 text-uppercase mt40'}>
                    BUY CREDITS
                </button>
                <button onClick={close} className={'btn-white w100 text-uppercase mt10'}>
                    GO BACK
                </button>
            </div>
        </div>
    )
}


const CreditHaveBeenDeleted: React.FC<{ close: () => void, title: string }> = ({ close, title }) => {
    return (
        <div>
            <div className={'flex justifyCenter'}>
                <img src={logoIcon} alt=""/>
            </div>
            <div className="heading4 text-center mt40">
                {title}
            </div>
            <p className={'p1 color-blue text-uppercase text-center mt20'}>
                1 credit has been charged Successfully.
            </p>
            <p className={'p1 color-blue text-uppercase text-center mt10'}>
                your request has been sent to your VLO AND
                WILL NOW APPEAR ON YOUR ACTIVE BOARD.
            </p>
            <div className={'mt20'}>
                <div className={'planner-page__modal-container'}>
                    ✅ 1 Credit
                </div>
            </div>
            <div>
                <button onClick={close} className={'btn btn-black w100 text-uppercase mt40'}>
                    OK
                </button>
            </div>
        </div>
    )
}